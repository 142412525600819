html, body {
  height: 100%;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.verify-form {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 auto;
  max-width: 600px;
  padding: 20px;
  text-align: center;
}

.title-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.logo {
  height: 60px;
  margin: 20px;
}

.header {
  font-size: 40px;
  font-weight: 400;

}

.form {
  margin-bottom: 20px;
  margin-top: 100px;
}

.input-box {
  width: 80%;
  padding: 15px;
  font-size: 25px;
  text-align: center;
  margin-bottom: 20px;
  border-radius: 100px;
  border: none;
  font-family: 'Roboto Mono', monospace;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.recaptcha-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.button-center {
  display: flex;
  justify-content: center;
}

.recaptcha {
  display: inline-block;
}

.verify-button {
  padding: 15px 120px;
  font-size: 30px;
  background-color: #EAB307;
  color: black;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  font-weight: 500;
}

.verify-button:hover {
  background-color: #45a049;
  color: white;
  font-weight: 800;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.error-box {
  color: red;
  font-size: xx-large;
  margin-bottom: 30px;
  font-family: 'Roboto Mono', monospace;
}

.details-table-container {
  text-align: left;
  margin: 0 auto;
  max-width: 500px;
}

.details-table {
  width: 100%;
  border-collapse: collapse;
}

.details-table th,
.details-table td {
  border: 1px solid #ddd;
  padding: 8px;
  font-weight: 400;
}

.details-table th {
  text-align: left;
  font-weight: 600;
  background-color: #f2f2f2;
}

.details-table td {
  background-color: #fff;
}

.frame {
  padding-bottom: 20px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: white;
  margin-bottom: 20px;
  margin-top: 10px;
  border: none;
  border-radius: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.btn {
  border: none;
  width: 15em;
  height: 5em;
  border-radius: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background: #1C1A1C;
  cursor: pointer;
  transition: all 450ms ease-in-out;
  font-family: 'Poppins', sans-serif;
}

.sparkle {
  fill: #AAAAAA;
  transition: all 800ms ease;
}

.text {
  font-weight: 600;
  color: #AAAAAA;
  font-size: medium;
}

.btn:hover {
  background: linear-gradient(0deg, #A47CF3, #683FEA);
  box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.4),
              inset 0px -4px 0px 0px rgba(0, 0, 0, 0.2),
              0px 0px 0px 4px rgba(255, 255, 255, 0.2),
              0px 0px 180px 0px #9917FF;
  transform: translateY(-2px);
}

.btn:hover .text {
  color: white;
}

.btn:hover .sparkle {
  fill: white;
  transform: scale(1.2);
}

.details-header {
  display: flex;
  justify-content: end;
  align-items: center;
}

.lottie-animation {
  margin-left: 10px;
}

.projectLink:link {
  color: #683FEA;
  background-color: transparent;
  text-decoration: underline;
}

.projectLink:visited {
  color: #683FEA;
  background-color: transparent;
  text-decoration: underline;
}

.projectLink:hover {
  color: green;
  background-color: transparent;
  text-decoration: underline;
}

.projectLink:active {
  color: #683FEA;
  background-color: transparent;
  text-decoration: underline;
}

.footer {
  margin-top: auto; /* Pushes the footer to the bottom of the container */
  text-align: center;
}

.icons {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.icons a {
  background: #ecf0f3;
  position: relative;
  height: 60px;
  width: 60px;
  margin: 0 10px;
  display: inline-flex;
  text-decoration: none;
  border-radius: 50%;
  transition: all 0.3s;
  box-shadow: -3px -3px 7px #ffffff, 3px 3px 5px #ceced1;
}

.icons a:hover:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  background: #ecf0f3;
  box-shadow: inset -3px -3px 7px #ffffff, inset 3px 3px 5px #ceced1;
}

.icons a i {
  position: relative;
  z-index: 3;
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 25px;
  line-height: 60px;
}

.icons a:hover i {
  transform: scale(0.9);
}

.icons a.ln i {
  color: #0077B5;
}

.icons a.twitter i {
  color: #1DA1F2;
}

.icons a.insta i {
  color: #E1306C;
}

.icons a.git i {
  color: #333;
}

.icons a.yt i {
  color: #ff0000;
}
body {
  font-family: 'Poppins', sans-serif;
}

.verify-form {
  font-family: 'Poppins', sans-serif;
  margin: 0 auto;
  max-width: 600px;
  padding: 20px;
  text-align: center;
}

.title-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.logo {
  height: 60px;
  margin: 20px;
}

.header {
  font-size: 40px;
  font-weight: 400;

}

.form {
  margin-bottom: 20px;
  margin-top: 100px;
}

.input-box {
  width: 80%;
  padding: 15px;
  font-size: 25px;
  text-align: center;
  margin-bottom: 20px;
  border-radius: 100px;
  border: none;
  font-family: 'Roboto Mono', monospace;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.recaptcha-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.button-center{
  display: flex;
  justify-content: center;
}

.recaptcha {
  display: inline-block;
}

.verify-button {
  padding: 15px 120px;
  font-size: 30px;
  background-color: #EAB307;
  color: black;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  font-weight: 500;
}

.verify-button:hover {
  background-color: #45a049;
  color: white;
  font-weight: 800;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.error-box {
  color: red;
  font-size: xx-large;
  margin-bottom: 30px;
  font-family: 'Roboto Mono', monospace;
}



.details-table-container {
  text-align: left;
  margin: 0 auto;
  max-width: 500px;
}

.details-table {
  width: 100%;
  border-collapse: collapse;
}

.details-table th,
.details-table td {
  border: 1px solid #ddd;
  padding: 8px;
  font-weight: 400;
}

.details-table th {
  text-align: left;
  font-weight: 600;
  background-color: #f2f2f2;
}

.details-table td {
  background-color: #fff;
}


.frame {
  padding-bottom: 20px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: white;
  margin-bottom: 20px;
  margin-top: 10px;
  border: none;
  border-radius: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}



.btn {
  border: none;
  width: 15em;
  height: 5em;
  border-radius: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background: #1C1A1C;
  cursor: pointer;
  transition: all 450ms ease-in-out;
  font-family: 'Poppins', sans-serif;
}

.sparkle {
  fill: #AAAAAA;
  transition: all 800ms ease;
}

.text {
  font-weight: 600;
  color: #AAAAAA;
  font-size: medium;
}

.btn:hover {
  background: linear-gradient(0deg,#A47CF3,#683FEA);
  box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.4),
  inset 0px -4px 0px 0px rgba(0, 0, 0, 0.2),
  0px 0px 0px 4px rgba(255, 255, 255, 0.2),
  0px 0px 180px 0px #9917FF;
  transform: translateY(-2px);
}

.btn:hover .text {
  color: white;
}

.btn:hover .sparkle {
  fill: white;
  transform: scale(1.2);
} 

.details-header {
  display: flex;
  justify-content:end;
  align-items: center;
}

.lottie-animation {
  margin-left: 10px;
}

.projectLink:link {
  color: #683FEA;
  background-color: transparent;
  text-decoration: underline;
}

.projectLink:visited {
  color: #683FEA;
  background-color: transparent;
  text-decoration: underline;
}

.projectLink:hover {
  color: green;
  background-color: transparent;
  text-decoration: underline;
}

.projectLink:active {
  color: #683FEA;
  background-color: transparent;
  text-decoration: underline;
}

.icons{
  margin: 80px;
}

.icons a{
  background: #ecf0f3;
  position: relative;
  height: 60px;
  width: 60px;
  margin: 0 10px;
  display: inline-flex;
  text-decoration: none;
  border-radius: 50%;
  transition: all 0.3s;
  box-shadow: -3px -3px 7px #ffffff,
              3px 3px 5px #ceced1;
}
.icons a:hover:before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  background: #ecf0f3;
  box-shadow: inset -3px -3px 7px #ffffff,
              inset 3px 3px 5px #ceced1;
}
.icons a i{
  position: relative;
  z-index: 3;
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 25px;
  line-height: 60px;
}
.icons a:hover i{
  transform: scale(0.9);
}
.icons a.ln i{
  color: #0077B5;
}
.icons a.twitter i{
  color: #1DA1F2;
}
.icons a.insta i{
  color: #E1306C;
}
.icons a.git i{
  color: #333;
}
.icons a.yt i{
  color: #ff0000;
}